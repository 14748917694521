import {debug} from 'console'
import {useAuth} from '../../../modules/auth'

export enum SWSection {
  Admin = 'Admin',
  Store = 'Store',
}

export enum SWAction {
  View = 'View',
  Search = 'Search',
  Create = 'Create',
  Update = 'Update',
  Delete = 'Delete',
  Export = 'Export',
}

export enum SWResource {
  Dashboard = 'Dashboard',

  Users = 'Users',
  UserRoles = 'UserRoles',
  Roles = 'Roles',
  RoleClaims = 'RoleClaims',
  Charges = 'Charges',

  PaymentMades = 'PaymentMades',
  // Countries = 'Countries',
  States = 'States',
  CustomerAddresses = 'CustomerAddresses',
  // UnitMeasurements = 'UnitMeasurements',
  // Colors = 'Colors',
  // Sizes = 'Sizes',
  PolishingTypes = 'PolishingTypes',
  Brands = 'Brands',
  Categories = 'Categories',
  // ItemGroups = 'ItemGroups',
  // DesignNumbers = 'DesignNumbers',
  Products = 'Products',
  // Tenants = 'Tenants',

  // Manufacturing
  // Locations = 'Locations',
  // Processes = 'Processes',
  // RawMaterials = 'RawMaterials',
  // ProductProcesses = 'ProductProcesses',
  Customers = 'Customers',
  Vendors = 'Vendors',
  SaleOrders = 'SaleOrders',
  Packages = 'Packages',
  Shipments = 'Shipments',
  // WorkOrders = 'WorkOrders',
  // WorkOrderProcesses = 'WorkOrderProcesses',
  PlaceOfSupplies = 'PlaceOfSupplies',
  Countries = 'Countries',
  Invoices = 'Invoices',
  PurchaseOrders = 'PurchaseOrders',
  PurchaseReceives = 'PurchaseReceives',
  Bills = 'Bills',
  OrganizationProfiles = 'OrganizationProfiles',
  TaxRates = 'TaxRates',
  TaxExemptions = 'TaxExemptions',
  DefaultTaxPreferences = 'DefaultTaxPreferences',
  GSTSettings = 'GSTSettings',
  CustomerVendorSettings = 'CustomerVendorSettings',
  SalesOrderSettings = 'SalesOrderSettings',
  InvoiceSettings = 'InvoiceSettings',
  CreditNoteSettings = 'CreditNoteSettings',
  PurchaseOrderSettings = 'PurchaseOrderSettings',
  EmailSettings = 'EmailSettings',
  EmailTemplates = 'EmailTemplates',
  AutoNumberGenerationGroups = 'AutoNumberGenerationGroups',
  Receipts = 'Receipts',
  Carts = 'Carts',
  ProductGroups = 'ProductGroups',
  ExpenseCategories = 'ExpenseCategories',
  Expenses = 'Expenses',
  Terms = 'Terms',
  DayBooks = 'DayBooks',
  Agents = 'Agents',
  Staffs = 'Staffs',
  PaymentModes = 'PaymentModes',
  PaymentReceived = 'PaymentReceived',

  Banks = 'Banks',

  // Comman
  DateFormats = 'DateFormats',
  DateFormatGroups = 'DateFormatGroups',
  TaxTypes = 'TaxTypes',
  TimeZones = 'TimeZones',
  Languages = 'Languages',

  // Accountant
  AccountGroups = 'AccountGroups',
  AccountTypes = 'AccountTypes',
  Accounts = 'Accounts',
  BankAccounts = 'BankAccounts',
  Manufacturers = 'Manufacturers',

  // Stock
  ProductStocks = 'ProductStocks',
  ProductStockTransactions = 'ProductStockTransactions',
  RawMaterialStocks = 'RawMaterialStocks',
  RawMaterialStockTransactions = 'RawMaterialStockTransactions',

  // barcode
  BarcodeSymbologies = 'BarcodeSymbologies',
}

export const SWPermissionNameFor = (section, resource, action) => {
  return `Permissions.${section}.${resource}.${action}`
}

export const MustHavePermission = (section, resource, action) => {
  let requestedPermission = SWPermissionNameFor(section, resource, action)
  // Fetch User Permission from Auth
  const {currentUser} = useAuth()
  return currentUser?.permissions?.includes(requestedPermission)
}
