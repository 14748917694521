import {Column} from 'primereact/column'
import {DataTable} from 'primereact/datatable'
import React, {useState} from 'react'
import {KTCard, KTCardBody, PaginationFilter} from '../../../../_metronic/helpers'
import {getLowProductStock} from './core/_request'
import 'flatpickr/dist/flatpickr.min.css'
import {useImmer} from 'use-immer'
import {useNavigate} from 'react-router-dom'
import {InputText} from 'primereact/inputtext'
import {useQuery} from 'react-query'
import {formatDate} from '../../../core/utils/helpers'
import ProductStockAdvanceSearch from '../stockReports/product-stock/components/ProductStockAdvanceSearch'

function Product() {
  const [filterQuery, setFilterQuery] = useState({})
  const [globalFilterValue, setGlobalFilterValue] = useState<string>('')
  const [paginationFilters, setPaginationFilters] = useImmer({
    pageNumber: 1,
    pageSize: 10,
    orderBy: ['LastUpdatedOn desc'] as string[],
    order: 'desc' as 'asc' | 'desc',
    first: 0,
    sortField: 'LastUpdatedOn',
  })

  const navigate = useNavigate()
  const {
    data: response,
    isLoading,
    refetch,
  } = useQuery(
    [`getLowProductStockQuery`, paginationFilters, filterQuery],
    () => {
      return getLowProductStock({
        ...filterQuery,
        ...paginationFilters,
        order: paginationFilters.order as 'asc' | 'desc',
      })
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const handleFilterQueryChange = (newFilterQuery: any) => {
    setFilterQuery(newFilterQuery)
  }

  const onSort = (event: any) => {
    setPaginationFilters((draft) => {
      draft.pageNumber = event.page === undefined ? 1 : (event.page as number) + 1
      draft.pageSize = event.rows
      draft.first = event.first
      draft.sortField = event.sortField
      event.sortField && (draft.order = event.sortOrder === 1 ? 'asc' : 'desc')
      event.sortField &&
        (draft.orderBy = [`${event.sortField} ${event.sortOrder === 1 ? 'asc' : 'desc'}`])
    })
  }

  const renderHeader = () => (
    <div className='flex justify-content-end'>
      <span className='p-input-icon-left'>
        <i className='pi pi-search' />
        <InputText
          placeholder='Keyword Search'
          value={globalFilterValue}
          onChange={(e) => setGlobalFilterValue(e.target.value)}
        />
      </span>
    </div>
  )

  const header = renderHeader()

  const filteredData = response?.data?.filter((item) =>
    Object.values(item).some((value: any) => {
      return (
        typeof value === 'string' && value.toLowerCase().includes(globalFilterValue.toLowerCase())
      )
    })
  )
  const leftContent = (
    <span className='mx-3' style={{color: 'var(--text-color)', userSelect: 'none'}}>
      {`Showing ${(response?.pagination?.totalCount as number) > 0 ? (paginationFilters.first as number) + 1 : 0} to 
            ${Math.min(
              (response?.pagination?.currentPage as number) *
                (response?.pagination?.pageSize as number),
              response?.pagination?.totalCount as number
            )} 
            out of ${response?.pagination?.totalCount} Records`}
    </span>
  )
  return (
    <>
      <ProductStockAdvanceSearch refetch={refetch} onFilterQueryChange={handleFilterQueryChange} />

      <KTCard>
        <div className='card-header justify-content-between align-items-center'>
          <div className='card-title'>
            <h3>Low Stock Product</h3>
          </div>
        </div>
        <KTCardBody>
          <>
            <div>
              <DataTable
                value={filteredData}
                stripedRows
                header={header}
                showGridlines
                filterDisplay='menu'
                tableClassName='table table-bordered table-hover mb-0'
                tableStyle={{minWidth: '50rem'}}
                sortOrder={paginationFilters.order === 'asc' ? 1 : -1}
                sortField={paginationFilters?.sortField}
                loading={isLoading}
                sortMode='single'
                currentPageReportTemplate='Showing {first} to {last} of {totalRecords} records'
                paginatorClassName='dataTables_paginate paging_bootstrap_full_number mt-0'
                paginator
                paginatorLeft={leftContent}
                rows={paginationFilters.pageSize}
                totalRecords={response?.pagination?.totalCount}
                emptyMessage='No Record found.'
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                onSort={onSort}
                onPage={onSort}
                first={paginationFilters.first}
                lazy
              >
                <Column
                  headerClassName='fw-semibold fs-6 text-gray-800'
                  field='productName'
                  header='Product '
                  sortable
                  sortField='Product.name'
                  style={{minWidth: '6rem'}}
                />

                <Column
                  headerClassName='fw-semibold fs-6 text-gray-800'
                  field='availableQuantity'
                  header='Quantity'
                  sortable
                  sortField='AvailableQuantity'
                />

                <Column
                  headerClassName='fw-semibold fs-6 text-gray-800'
                  field='lowStockAlert'
                  header='Low Stock Alert'
                  sortable
                  sortField='Product.LowStockAlert'
                />

                <Column
                  headerClassName='fw-semibold fs-6 text-gray-800'
                  field='colorName'
                  header='Color'
                  sortable
                  sortField='Product.ColorId'
                />
                <Column
                  headerClassName='fw-semibold fs-6 text-gray-800'
                  field='polishingTypeName'
                  header='Polishing Type'
                  sortable
                  sortField='Product.PolishingTypeId'
                />
                <Column
                  headerClassName='fw-semibold fs-6 text-gray-800'
                  field='lastUpdatedOn'
                  header='Last Updated On'
                  sortable
                  sortField='LastUpdatedOn'
                  body={(rowData) => formatDate(rowData?.lastUpdatedOn, 'dd MMM yyyy')}
                />
              </DataTable>
            </div>
          </>
        </KTCardBody>
        <div className='card-footer text-end'>
          <button className='btn btn-primary' onClick={() => navigate('/reports/stocks/list')}>
            <i className='ki-duotone ki-left fs-2' /> Back{' '}
          </button>
        </div>
      </KTCard>
    </>
  )
}

export default Product
