import React, {useEffect, useState} from 'react'
import {KTCard, KTCardBody} from '../../../../_metronic/helpers'
import {Link} from 'react-router-dom'
import {OrganizationProfile} from '../core/_models'
import {getOrganizatinProfile} from '../core/_requests'
import {useLocation} from 'react-router'
import {useImage} from '../../../core/models/ImageContext'

const GeneralHeader = () => {
  const API_URL = process.env.REACT_APP_IMAGE_API_URL

  const [orgProfile, setOrgProfile] = useState<OrganizationProfile>()
  const location = useLocation()
  const {imageSelected} = useImage()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getOrganizatinProfile()
        const profile = response as OrganizationProfile
        setOrgProfile(profile)
      } catch (error) {
        console.error(error)
      }
    }

    fetchData()
  }, [])

  return (
    <div className='card mb-5 mb-xl-5'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap'>
          <div className='me-7 mb-4'>
            <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
              {imageSelected?.data ? (
                <img src={`${imageSelected.data}`} alt='Uploaded2' className='img-responsive' />
              ) : (
                <img
                  //src={`${API_URL}/${orgProfile?.logo}`}
                  src={'../media/icons/No_Image_Available.webp'}
                  alt='Uploaded'
                  className='img-responsive'
                />
              )}
            </div>
          </div>

          <div className='flex-grow-1'>
            <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center mb-2'>
                  <a href='#' className='text-gray-900 text-hover-primary fs-2 fw-bold me-1'>
                    {orgProfile?.organizationName}
                  </a>
                  <a href='#'>
                    <i className='ki-duotone ki-verify fs-1 text-primary'>
                      <span className='path1' />
                      <span className='path2' />
                    </i>
                  </a>
                </div>

                <div className='d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2'>
                  <a href='#' className='d-flex  text-gray-500 text-hover-primary me-5 mb-2'>
                    <i className='ki-duotone ki-geolocation fs-4 me-1'>
                      <span className='path1' />
                      <span className='path2' />
                    </i>
                    <p>
                      {orgProfile?.address?.addressLine1} <br />
                      {orgProfile?.address?.addressLine2} <br />
                      {orgProfile?.address?.city}
                    </p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold'>
          <li className='nav-item mt-2'>
            <Link
              className={
                `nav-link text-active-primary me-6 ` +
                (location.pathname === '/settings/general' && 'active')
              }
              to={'/settings/general'}
            >
              Overview{' '}
            </Link>
          </li>

          <li className='nav-item mt-2'>
            <Link
              to={'/settings/organizationprofile/list'}
              className={
                `nav-link text-active-primary me-6 ` +
                (location.pathname === '/settings/organizationprofile/list' && 'active')
              }
            >
              {' '}
              General Settings{' '}
            </Link>
          </li>

          <li className='nav-item mt-2'>
            {/* {MustHavePermission(SWSection.Store, SWResource.GSTSettings, SWAction.View) && ( */}
            <Link
              to={'/settings/gstsetting'}
              className={
                `nav-link text-active-primary me-6 ` +
                (location.pathname === '/settings/gstsetting' && 'active')
              }
            >
              GST Setting
            </Link>
            {/* )} */}
          </li>
          <li className='nav-item mt-2'>
            <Link
              to={'/settings/address'}
              className={
                `nav-link text-active-primary me-6 ` +
                (location.pathname === '/settings/address' && 'active')
              }
            >
              Delivery Addresses
            </Link>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default GeneralHeader
