import React, {FC, useEffect, useState} from 'react'
import {Result} from '../../../../core/models/Result'
import {EditRoleModel, Role} from '../core/_models'
import {KTCard, KTCardBody} from '../../../../../_metronic/helpers'
import {toast} from 'react-toastify'
import {useNavigate} from 'react-router-dom'
import {
  SWSection,
  SWResource,
  SWAction,
  SWPermissionNameFor,
} from '../../../../core/shared/core/permissionUtils'
import {updateRolePermissions} from '../core/_requests'
import {PermissionList} from '../../../../core/shared/core/_model'

type Props = {
  isRoleLoading?: boolean
  data: Role | Result
  allPermissions: PermissionList[] | any
}

const PermissionsPage: FC<Props> = ({isRoleLoading, data, allPermissions}) => {
  const permission = data as Role

  const initialSelectedPermissions = permission.permissions || []
  const [selectedPermissions, setSelectedPermissions] = useState<string[]>(
    initialSelectedPermissions
  )
  const navigate = useNavigate()

  const updateDependencies = (updatedPermissions: string[], dependent: any) => {
    // debugger
    const newPermissions = [...updatedPermissions]
    dependent.dependentPermissions.map((permission) => {
      if (updatedPermissions.includes(permission)) {
        dependent?.dependentPermissions?.forEach((dep) => {
          if (!newPermissions.includes(dep)) {
            newPermissions.push(dep)
          }
        })
      } else {
        dependent.dependentPermissions.forEach((deps) => {
          // dependent.dependentPermissions.forEach((dep) => {
          if (
            newPermissions.includes(deps) &&
            !dependent.dependentPermissions.some((key) => newPermissions.includes(key))
          ) {
            newPermissions.splice(newPermissions.indexOf(deps), 1)
          } else {
            // toast.warn('Dependent Permission')
          }
        })
        // })
      }
    })
    return newPermissions
  }

  const handlePermissionChange = (
    section: SWSection,
    resource: SWResource,
    action: SWAction,
    dependentPermissions: any
  ) => {
    console.log(dependentPermissions)
    const permissionKey = SWPermissionNameFor(section, resource, action)
    const isChecked = selectedPermissions.includes(permissionKey)
    const updatedPermissions = isChecked
      ? selectedPermissions.filter((p) => p !== permissionKey)
      : [...selectedPermissions, permissionKey]
    const newPermissions = updateDependencies(updatedPermissions, dependentPermissions)
    setSelectedPermissions(newPermissions)
  }

  const savePermissions = () => {
    const updatePermission: EditRoleModel = {
      id: permission.id as number,
      permissions: selectedPermissions,
    }
    updateRolePermissions(updatePermission)
      .then(() => {
        toast.success('Permission updated successfully')
        navigate('/settings/role/list')
      })
      .catch((error) => {
        console.error('Error updating permissions', error)
      })
  }

  useEffect(() => {
    setSelectedPermissions(initialSelectedPermissions)
  }, [initialSelectedPermissions])

  const groupBy = (array, key) => {
    return array.reduce((result, currentValue) => {
      ;(result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue)
      return result
    }, {})
  }
  const allPermissionsList = groupBy(allPermissions, 'resource')

  return (
    <KTCard>
      <div className='card-header'>
        <div className='card-title'>
          <h3>Update Permissions</h3>
        </div>
      </div>
      <KTCardBody>
        <div className='row mb-5'>
          <label className='col-xl-2 col-lg-2 col-md-3 col-form-label fw-semibold fs-6'>
            Selected Role
          </label>
          <div className='col-xl-2 col-xl-4 col-lg-4 col-md-5 fv-row fv-plugins-icon-container'>
            <label className='form-control'>{permission.name}</label>
          </div>
        </div>

        <table className='table .table-striped table-bordered'>
          <thead className='bg-light'>
            <tr>
              <th scope='col' className='p-2'>
                Page Name
              </th>
              <th scope='col' className='p-2'>
                Permissions
              </th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(allPermissionsList)?.map((item, index) => (
              <tr key={index}>
                <td className='p-2'>{item[0]}</td>
                <td className='p-2'>
                  {Array.isArray(item[1]) &&
                    item[1]?.map((a: any, actionIndex) => (
                      <div key={actionIndex}>
                        <input
                          type='checkbox'
                          className='check_permission'
                          name=''
                          onChange={() =>
                            handlePermissionChange(a.section, a.resource, a.action, a)
                          }
                          checked={selectedPermissions.includes(
                            SWPermissionNameFor(a.section, a.resource, a.action)
                          )}
                        />
                        <span className='ms-2'>
                          {a.section} - {a.action}
                        </span>
                        <br />
                      </div>
                    ))}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className='text-end'>
          <button className='btn btn-primary' onClick={savePermissions}>
            Save Changes
          </button>
        </div>
      </KTCardBody>
    </KTCard>
  )
}

export default PermissionsPage
