import {Column} from 'primereact/column'
import {DataTable} from 'primereact/datatable'
import React, {useState} from 'react'
import {KTCard, KTCardBody} from '../../../../_metronic/helpers'
import {FilterOption, StockFilter} from './core/_model'
import {GeneratePdf, getPurchaseProductGroupStock} from './core/_request'
import Flatpickr from 'react-flatpickr'
import 'flatpickr/dist/flatpickr.min.css'
import {format} from 'date-fns'
import {toZonedTime} from 'date-fns-tz'
import {useNavigate} from 'react-router-dom'
import {InputText} from 'primereact/inputtext'
import {useQuery} from 'react-query'
import {FileResult} from '../../../core/models/Result'
import {toast} from 'react-toastify'
import {saveAs} from 'file-saver'
import {Calendar} from 'primereact/calendar'
import {ColumnGroup} from 'primereact/columngroup'
import {Row} from 'primereact/row'
import {
  PaginatorCurrentPageReportOptions,
  PaginatorRowsPerPageDropdownOptions,
} from 'primereact/paginator'
import {Dropdown} from 'primereact/dropdown'

function PurchaseByProductGroup() {
  const [filters, setFilters] = useState<StockFilter>({})
  const [advancedSearchVisible, setAdvancedSearchVisible] = useState(false)
  const [filterOptions, setFilterOptions] = useState<FilterOption>({
    filterDates: undefined,
  })
  const [globalFilterValue, setGlobalFilterValue] = useState<string>('')
  const navigate = useNavigate()
  const {
    isFetching,
    data: response,
    isLoading,
  } = useQuery(
    [`getPurchaseByVendorQuery`, filters],
    () => {
      return getPurchaseProductGroupStock(filters)
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const updateSearchFilters = () => {
    const filter: StockFilter = {}
    if (filterOptions?.filterDates != undefined) {
      if (filterOptions?.filterDates?.[0] !== undefined) {
        const fromDate = toZonedTime(
          new Date(filterOptions?.filterDates?.[0] as Date),
          'Asia/Kolkata'
        )
        filter.startDate = format(fromDate, "yyyy-MM-dd'T'HH:mm:ssXXX")
      }

      if (filterOptions?.filterDates?.[1] === null) {
        const toDate = toZonedTime(new Date(filterOptions.filterDates[0] as Date), 'Asia/Kolkata')

        filter.endDate = format(toDate, "yyyy-MM-dd'T'HH:mm:ssXXX")
      } else {
        const toDate = toZonedTime(new Date(filterOptions.filterDates[1] as Date), 'Asia/Kolkata')

        filter.endDate = format(toDate, "yyyy-MM-dd'T'HH:mm:ssXXX")
      }
    }
    setFilters(filter)
  }

  const renderHeader = () => (
    <div className='flex justify-content-end'>
      <span className='p-input-icon-left'>
        <i className='pi pi-search' />
        <InputText
          placeholder='Keyword Search'
          value={globalFilterValue}
          onChange={(e) => setGlobalFilterValue(e.target.value)}
        />
      </span>
    </div>
  )

  const header = renderHeader()
  const filteredData = response?.filter((item) =>
    Object.values(item).some((value: any) => {
      return (
        typeof value === 'string' && value.toLowerCase().includes(globalFilterValue.toLowerCase())
      )
    })
  )

  const Download_URL = 'report/download-purchases-by-product-group-excel'
  const challanpdf = () => {
    GeneratePdf(filters, Download_URL).then((file) => {
      const output = file as FileResult

      if (output.data) {
        const url = URL.createObjectURL(output?.data)
        saveAs(url, output.name)
      } else {
        toast.error(file.exception)
      }
    })
  }

  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column />
        <Column footer='SubTotal:' footerStyle={{textAlign: 'right'}} />
        <Column footer={filteredData?.reduce((acc, item) => acc + (item?.quantity || 0), 0)} />
      </Row>
    </ColumnGroup>
  )

  const paginatorTemplate = {
    layout: 'CurrentPageReport PrevPageLink PageLinks NextPageLink  RowsPerPageDropdown',
    CurrentPageReport: (options: PaginatorCurrentPageReportOptions) => {
      return (
        <span
          style={{
            color: 'var(--text-color)',
            userSelect: 'none',
            textAlign: 'left',
          }}
        >
          {`Showing ${options.first} to ${options.last} of ${options.totalRecords} records`}
        </span>
      )
    },
    RowsPerPageDropdown: (options: PaginatorRowsPerPageDropdownOptions) => {
      const dropdownOptions = [
        {label: 10, value: 10},
        {label: 25, value: 25},
        {label: 50, value: 50},
      ]
      return (
        <div className='flex align-items-center'>
          <React.Fragment>
            <span className='mx-1' style={{color: 'var(--text-color)', userSelect: 'none'}}></span>
            <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
          </React.Fragment>
        </div>
      )
    },
  }

  return (
    <>
      <div className='card mb-5'>
        <div className='card-header justify-content-between align-items-center'>
          <div className='card-title'>
            <h3>Purchase By Product Group FIlter</h3>
          </div>
          <button className='btn' onClick={() => setAdvancedSearchVisible(!advancedSearchVisible)}>
            {advancedSearchVisible ? (
              <img src='/media/icons/up-angle.svg' alt='Filter' height={25} width={25} />
            ) : (
              <img src='/media/icons/down-angle.svg' alt='Filter' height={25} width={25} />
            )}
          </button>
        </div>
        {advancedSearchVisible && (
          <>
            <div className='card-body'>
              <div className='collapse' id='kt_advanced_search_form'>
                <div className='separator separator-dashed mt-9 mb-6'></div>
              </div>

              <div className='col-lg-4'>
                <label className='fs-6 form-label fw-bold text-dark'>Date</label>
                <div className='row g-3'>
                  <Calendar
                    value={filterOptions?.filterDates as Date[]}
                    onChange={(e) => {
                      setFilterOptions({...filterOptions, filterDates: e.value as Date[]})
                    }}
                    selectionMode='range'
                    placeholder='Select Date Range'
                    formatDateTime={(value) => format(value, 'dd/MM/yyyy')}
                    readOnlyInput
                    hideOnRangeSelection
                    style={{width: '20rem', height: '3rem'}}
                    showButtonBar
                    className='w-100'
                  />
                </div>
              </div>
            </div>
            <div className='card-footer text-end'>
              <div className=' d-flex gap-3 justify-content-end'>
                <button
                  className='btn btn-primary'
                  id='btnSearch'
                  onClick={() => {
                    setFilters({})
                    setFilterOptions({filterDates: undefined})
                  }}
                >
                  Clear Filters
                </button>
                <button className='btn btn-primary' id='btnSearch' onClick={updateSearchFilters}>
                  Search
                </button>
              </div>
            </div>
          </>
        )}
      </div>

      <KTCard>
        <div className='card-header justify-content-between align-items-center'>
          <div className='card-title'>
            <h3>Purchase By Product Group</h3>
          </div>
          <button className='btn btn-primary' id='btnSearch' onClick={() => challanpdf()}>
            Export Data
          </button>
        </div>
        <KTCardBody>
          <>
            {/* <div className='card-header border-0 pt-6 '>
              <div className='surface-section px-4 py-5 md:px-6 lg:px-8'>
                <div className='flex flex-column md:align-items-center md:justify-content-between md:flex-row'>
                  <div className='h2'>Sales By Customer</div>
                </div>
              </div>
            </div> */}
            <div>
              <DataTable
                tableClassName='table table-bordered table-hover mb-0'
                stripedRows
                loading={isLoading}
                header={header}
                value={filteredData}
                showGridlines
                filterDisplay='menu'
                tableStyle={{minWidth: '50rem'}}
                paginator
                footerColumnGroup={footerGroup}
                paginatorClassName='dataTables_paginate paging_bootstrap_full_number mt-0'
                paginatorTemplate={paginatorTemplate}
                emptyMessage='No Record found.'
                currentPageReportTemplate='{first} to {last} of {totalRecords}'
                rows={10}
              >
                <Column
                  headerClassName='fw-semibold fs-6 text-gray-800'
                  field='productGroupName'
                  header='Product Group '
                  sortable
                  style={{minWidth: '6rem'}}
                />

                <Column
                  headerClassName='fw-semibold fs-6 text-gray-800'
                  field='categoryName'
                  header='Category'
                  sortable
                />

                <Column
                  headerClassName='fw-semibold fs-6 text-gray-800'
                  field='quantity'
                  header='Quantity'
                  sortable
                />
              </DataTable>
            </div>
          </>
        </KTCardBody>
        <div className='card-footer text-end'>
          <button className='btn btn-primary' onClick={() => navigate('/reports/stocks/list')}>
            <i className='ki-duotone ki-left fs-2' /> Back
          </button>
        </div>
      </KTCard>
    </>
  )
}

export default PurchaseByProductGroup
