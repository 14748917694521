import React, {useState} from 'react'
import {format} from 'date-fns'
import {toZonedTime} from 'date-fns-tz'
import {useQuery} from 'react-query'
import {Calendar} from 'primereact/calendar'
import {MultiSelect} from 'primereact/multiselect'
import {getColorList, getPolishingTypeList} from '../../../../../core/shared/core/_request'
import {FilterOption} from '../core/_model'
import {Filter} from '../../../../../../_metronic/helpers'
import {InputText} from 'primereact/inputtext'

const ProductStockAdvanceSearch = ({
  refetch,
  onFilterQueryChange,
}: {
  refetch: () => void
  onFilterQueryChange: (query: any) => void
}) => {
  const [advancedSearchVisible, setAdvancedSearchVisible] = useState(false)
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined)
  const [filterOptions, setFilterOptions] = useState<FilterOption>({
    filterProductName: '',
    filterDates: undefined,
    filterColorList: [],
    filterPolishTypeList: [],
  })
  const [filterQuery, setFilterQuery] = useState({})
  const {data: polishingTypeList, isLoading: polishTypeListLoading} = useQuery({
    queryKey: ['polishTypeListQuery'],
    queryFn: () => getPolishingTypeList({}),
    refetchOnWindowFocus: false,
    cacheTime: 0,
  })

  const {data: colorList} = useQuery({
    queryKey: ['colorListQuery'],
    queryFn: () => getColorList({}),
    refetchOnWindowFocus: false,
    cacheTime: 0,
  })

  const updateSearchFilters = () => {
    let filters: Filter[] = []

    if (filterOptions.filterProductName != undefined && filterOptions.filterProductName != '') {
      filters.push({
        field: 'Product.name',
        operator: 'contains',
        value: filterOptions.filterProductName,
      })
    }

    if (filterOptions?.filterColorList != undefined) {
      if (filterOptions?.filterColorList?.length == 1) {
        filters.push({
          field: 'Product.ColorId',
          operator: 'eq',
          value: Number(filterOptions?.filterColorList[0].id),
        })
      } else {
        let colorFilters: Filter = {
          logic: 'or',
          filters: [],
        }
        if (filterOptions?.filterColorList.length > 1) {
          filterOptions?.filterColorList?.map((item: any) => {
            colorFilters?.filters?.push({
              field: 'Product.ColorId',
              operator: 'eq',
              value: Number(item.id),
            })
          })
          filters.push(colorFilters)
        }
      }
    }
    if (filterOptions?.filterPolishTypeList != undefined) {
      if (filterOptions?.filterPolishTypeList?.length == 1) {
        filters.push({
          field: 'Product.PolishingTypeId',
          operator: 'eq',
          value: Number(filterOptions?.filterPolishTypeList[0].id),
        })
      } else {
        let polishTypeFilters: Filter = {
          logic: 'or',
          filters: [],
        }
        if (filterOptions?.filterPolishTypeList.length > 1) {
          filterOptions?.filterPolishTypeList?.map((item: any) => {
            polishTypeFilters?.filters?.push({
              field: 'Product.PolishingTypeId',
              operator: 'eq',
              value: Number(item.id),
            })
          })
          filters.push(polishTypeFilters)
        }
      }
    }

    if (filterOptions?.filterDates != undefined) {
      let orderDateFilters: Filter[] = []
      if (filterOptions?.filterDates?.[0] !== undefined) {
        const fromDate = toZonedTime(
          new Date(filterOptions?.filterDates?.[0] as Date),
          'Asia/Kolkata'
        )
        orderDateFilters.push({
          field: 'LastUpdatedOn',
          operator: 'gte',
          value: format(fromDate, 'yyyy-MM-dd 00:00:00'),
        })
      }

      if (filterOptions?.filterDates?.[1] === null) {
        const toDate = toZonedTime(new Date(filterOptions.filterDates[0] as Date), 'Asia/Kolkata')

        orderDateFilters.push({
          field: 'LastUpdatedOn',
          operator: 'lte',
          value: format(toDate, 'yyyy-MM-dd 23:59:59'),
        })
      } else {
        const toDate = toZonedTime(new Date(filterOptions.filterDates[1] as Date), 'Asia/Kolkata')

        orderDateFilters.push({
          field: 'BillDate',
          operator: 'lte',
          value: format(toDate, 'yyyy-MM-dd 23:59:59'),
        })
      }

      filters.push({
        filters: orderDateFilters,
        logic: 'and',
      })
    }

    if (filters.length > 1) {
      const newFilterQuery = {
        ...filterQuery,
        advancedFilter: {
          filters: filters,
          logic: 'and',
        },
      }
      setFilterQuery(newFilterQuery)
      onFilterQueryChange(newFilterQuery)
    } else if (filters.length === 1) {
      const newFilterQuery = {
        ...filterQuery,
        keyword: searchTerm,
        advancedFilter: filters[0],
      }
      setFilterQuery(newFilterQuery)
      onFilterQueryChange(newFilterQuery)
    } else {
      const newFilterQuery = {
        ...filterQuery,
        keyword: searchTerm,
        advancedFilter: undefined,
      }
      setFilterQuery(newFilterQuery)
      onFilterQueryChange(newFilterQuery)
    }
  }

  const clearFilters = () => {
    setFilterQuery({
      keyword: '',
      advancedFilter: undefined,
    })
    setFilterOptions({
      filterProductName: '',
      filterDates: undefined,
      filterColorList: [],
      filterPolishTypeList: [],
    })
    onFilterQueryChange({})
    refetch()
  }

  return (
    <>
      <div className='card mb-5'>
        <div className='card-header justify-content-between align-items-center'>
          <div className='card-title'>
            <h3>Product Stock Filter</h3>
          </div>
          <button className='btn' onClick={() => setAdvancedSearchVisible(!advancedSearchVisible)}>
            {advancedSearchVisible ? (
              <img src='/media/icons/up-angle.svg' alt='Filter' height={25} width={25} />
            ) : (
              <img src='/media/icons/down-angle.svg' alt='Filter' height={25} width={25} />
            )}
          </button>
        </div>
        {advancedSearchVisible && (
          <>
            <div className='card-body'>
              <div className='row g-5 mb-5'>
                <div className='col-lg-4'>
                  <label className='fs-6 form-label fw-bold text-dark'>Product Name</label>
                  <div className=''>
                    <InputText
                      name='productName'
                      placeholder='Enter Product Name'
                      autoComplete='off'
                      value={filterOptions?.filterProductName}
                      onChange={(e) =>
                        setFilterOptions({...filterOptions, filterProductName: e.target.value})
                      }
                      style={{height: '3rem'}}
                      className='form-control'
                    />
                  </div>
                </div>
                <div className='col-lg-4'>
                  <label className='fs-6 form-label fw-bold text-dark'>Color</label>
                  <div className='w-100'>
                    <MultiSelect
                      value={filterOptions.filterColorList}
                      onChange={(e) =>
                        setFilterOptions({...filterOptions, filterColorList: e.value})
                      }
                      options={colorList?.data?.filter((t) => t.name !== 'NA')}
                      optionLabel='name'
                      display='chip'
                      onRemove={(e) =>
                        setFilterOptions({...filterOptions, filterColorList: e.value})
                      }
                      placeholder='Select Colors'
                      //   maxSelectedLabels={3}
                      style={{height: '3rem'}}
                      className='w-100'
                    />
                  </div>
                </div>
                <div className='col-lg-4'>
                  <label className='fs-6 form-label fw-bold text-dark'>Polish Type</label>
                  <div className='w-100'>
                    <MultiSelect
                      value={filterOptions.filterPolishTypeList}
                      onChange={(e) =>
                        setFilterOptions({...filterOptions, filterPolishTypeList: e.value})
                      }
                      options={polishingTypeList?.data?.filter((t) => t.name !== 'NA')}
                      optionLabel='name'
                      display='chip'
                      onRemove={(e) =>
                        setFilterOptions({...filterOptions, filterPolishTypeList: e.value})
                      }
                      placeholder='Select Polish Type'
                      maxSelectedLabels={3}
                      style={{height: '3rem'}}
                      className='w-100'
                    />
                  </div>
                </div>
                <div className='col-lg-4'>
                  <label className='fs-6 form-label fw-bold text-dark'>Last Updated on</label>
                  <div className='w-100 p-calendar-wraper'>
                    <Calendar
                      value={filterOptions?.filterDates as Date[]}
                      onChange={(e) => {
                        setFilterOptions({...filterOptions, filterDates: e.value as Date[]})
                      }}
                      selectionMode='range'
                      placeholder='Select Date Range'
                      formatDateTime={(value) => format(value, 'dd/MM/yyyy')}
                      readOnlyInput
                      hideOnRangeSelection
                      style={{width: '20rem', height: '3rem'}}
                      showButtonBar
                      className='w-100'
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='card-footer d-flex gap-3 justify-content-end'>
              <button className='btn btn-primary' id='btnSearch' onClick={clearFilters}>
                Clear Filters
              </button>
              <button className='btn btn-primary' id='btnSearch' onClick={updateSearchFilters}>
                Search
              </button>
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default ProductStockAdvanceSearch
