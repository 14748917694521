import {Route, Routes, Outlet} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {
  MustHavePermission,
  SWAction,
  SWResource,
  SWSection,
} from '../../../core/shared/core/permissionUtils'
import UnAuthorized from '../../errors/components/UnAuthorized'
import PaymentMadeList from './pages/PaymentMadeList'
import PaymentMadeDetails from './pages/PaymentMadeDetails'
import PaymentMadeAdd from './pages/PaymentMadeAdd'

const Breadcrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/payment-made',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const PaymentMadePage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='list'
          index
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>Payment Made Records</PageTitle>
              {MustHavePermission(SWSection.Store, SWResource.PaymentMades, SWAction.Search) ? (
                <PaymentMadeList />
              ) : (
                <UnAuthorized />
              )}
            </>
          }
        />

        {/* to navigate to add page */}
        <Route
          path='add'
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>Add Payment Made Record</PageTitle>
              {MustHavePermission(SWSection.Store, SWResource.PaymentMades, SWAction.Create) ? (
                <PaymentMadeAdd />
              ) : (
                <UnAuthorized />
              )}
            </>
          }
        />
        <Route
          path='add/:id'
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>Add Payment Made Record</PageTitle>
              {MustHavePermission(SWSection.Store, SWResource.PaymentMades, SWAction.Create) ? (
                <PaymentMadeAdd />
              ) : (
                <UnAuthorized />
              )}
            </>
          }
        />
        <Route
          path='detail/:id'
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>Payment Made Details</PageTitle>
              {MustHavePermission(SWSection.Store, SWResource.PaymentMades, SWAction.View) ? (
                <PaymentMadeDetails />
              ) : (
                <UnAuthorized />
              )}
            </>
          }
        />
      </Route>
    </Routes>
  )
}

export default PaymentMadePage
