import {Routes, Route, Outlet} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {PaymentDetailWrapper} from './pages/PaymentDetailWrapper'
import PaymentList from './pages/PaymentList'
import AddPayment from './pages/AddPayment'
import {
  MustHavePermission,
  SWAction,
  SWResource,
  SWSection,
} from '../../../core/shared/core/permissionUtils'
import UnAuthorized from '../../errors/components/UnAuthorized'

const Breadcrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
const PaymentPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='list'
          index
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>Payment</PageTitle>
              {MustHavePermission(SWSection.Store, SWResource.PaymentReceived, SWAction.View) ? (
                <PaymentList />
              ) : (
                <UnAuthorized />
              )}
            </>
          }
        />

        <Route
          path='detail/:id'
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>Detail Payment</PageTitle>
              {MustHavePermission(SWSection.Store, SWResource.PaymentReceived, SWAction.View) ? (
                <PaymentDetailWrapper />
              ) : (
                <UnAuthorized />
              )}
            </>
          }
        />

        <Route
          path='add'
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>Add Payment</PageTitle>
              {MustHavePermission(SWSection.Store, SWResource.PaymentReceived, SWAction.Create) ? (
                <AddPayment />
              ) : (
                <UnAuthorized />
              )}
            </>
          }
        />
        <Route
          path='add/:id'
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>Add Payment</PageTitle>
              {MustHavePermission(SWSection.Store, SWResource.PaymentReceived, SWAction.Create) ? (
                <AddPayment />
              ) : (
                <UnAuthorized />
              )}
            </>
          }
        />
      </Route>
    </Routes>
  )
}
export default PaymentPage
