import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios'
import {AuthModel, getAuth} from '../../modules/auth'
import {Result} from '../models/Result'
import {NavigateFunction} from 'react-router-dom'
import {refreshtoken} from '../../modules/auth/core/_requests'

const onRequest = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  const auth = getAuth()

  if (auth && auth.token) {
    config.headers.Authorization = `Bearer ${auth.token}`
  }

  return config
}

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  return Promise.reject(error)
}

const onResponse = (response: AxiosResponse): AxiosResponse => {
  return response
}

const onResponseError = async (
  error: AxiosError | Error,
  navigate: NavigateFunction,
  saveAuth: (auth: AuthModel | undefined) => void,
  logout: () => void
): Promise<Result | undefined> => {
  if (axios.isAxiosError(error)) {
    const {message} = error
    const {method, url} = error.config as AxiosRequestConfig
    const {statusText, status} = (error.response as AxiosResponse) ?? {}

    switch (status) {
      case 400: {
        // Bad Request
        return Promise.reject(error.response?.data)
        break
      }
      case 401: {
        let exception = error.response?.data?.exception
        if (exception === 'Invalid Refresh Token.' || exception === 'Authentication Failed.') {
          RedirectToLogin(navigate, logout)
          return Promise.resolve(undefined)
        }

        const result = await RefreshAccessToken(navigate, saveAuth, logout)

        if (result) {
          // Retry the original request with the new access token
          const originalRequest = error.config
          if (originalRequest != null) {
            originalRequest.headers.Authorization = `Bearer ${result.token}`
            return axios(originalRequest)
          }
        } else {
          RedirectToLogin(navigate, logout)
        }

        return Promise.resolve(undefined)
        // "Login required"
      }
      case 403: {
        // "Permission denied"
        navigate('error/access-denied')
        return Promise.resolve(undefined)
      }
      case 404: {
        // "Invalid request"
        navigate('error/404')
        return Promise.resolve(undefined)
      }
      case 500: {
        // "Server error"
        // navigate('error/500')
        return Promise.resolve(error.response?.data)
        //return Promise.resolve(undefined);
      }
      default: {
        // "Unknown error occurred"
        RedirectToLogin(navigate, logout)
        return Promise.resolve(undefined)
      }
    }
  }

  return Promise.reject(error)
}

// Function to redirect to the login page
export const RedirectToLogin = (navigate: NavigateFunction, logout: () => void) => {
  logout()
  navigate('/auth')
}

// Function to refresh the access token using the refresh token
export const RefreshAccessToken = async (
  navigate: NavigateFunction,
  saveAuth: (auth: AuthModel | undefined) => void,
  logout: () => void
) => {
  const auth = getAuth()
  console.log(auth)
  console.log(auth?.refreshToken)
  let refreshToken = auth?.refreshToken
  if (!auth || !refreshToken) {
    RedirectToLogin(navigate, logout)
    return null
  }

  try {
    const {data: response} = await refreshtoken(auth.token, refreshToken)
    saveAuth(response)
    return response
  } catch (error) {
    // Handle errors that occur during the token refresh process
    RedirectToLogin(navigate, logout)
  }
}

export function setupInterceptorsTo(
  axiosInstance: AxiosInstance,
  navigate: NavigateFunction,
  saveAuth: (auth: AuthModel | undefined) => void,
  logout: () => void
): AxiosInstance {
  axiosInstance.interceptors.request.use(onRequest, onRequestError)
  axiosInstance.interceptors.response.use(onResponse, (error) => {
    return onResponseError(error, navigate, saveAuth, logout)
  })
  return axiosInstance
}
