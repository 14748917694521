import {useQuery} from 'react-query'
import {KTCard, KTCardBody} from '../../../../../_metronic/helpers'
import {getProductStockList} from './core/_request'
import {useState} from 'react'
import {useImmer} from 'use-immer'
import {InputText} from 'primereact/inputtext'
import {DataTable} from 'primereact/datatable'
import {Loading} from '../../../../core/shared/components/Loading'
import {Column} from 'primereact/column'
import {formatCurrency, formatDate} from '../../../../core/utils/helpers'
import ProductStockAdvanceSearch from './components/ProductStockAdvanceSearch'
import {useNavigate} from 'react-router-dom'

const ProductStockList = () => {
  const [filterQuery, setFilterQuery] = useState({})
  const navigate = useNavigate()
  const [globalFilterValue, setGlobalFilterValue] = useState<string>('')
  const [paginationFilters, setPaginationFilters] = useImmer({
    pageNumber: 1,
    pageSize: 10,
    orderBy: [] as string[],
    order: '' as 'asc' | 'desc',
    first: 0,
    sortField: '',
  })

  const handleFilterQueryChange = (newFilterQuery: any) => {
    setFilterQuery(newFilterQuery)
  }
  const {
    isFetching,
    refetch,
    data: response,
    isLoading,
  } = useQuery(
    [`getProductStock`, paginationFilters, filterQuery],
    () => {
      return getProductStockList({
        ...filterQuery,
        ...paginationFilters,
        order: paginationFilters.order as 'asc' | 'desc',
      })
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const onSort = (event: any) => {
    setPaginationFilters((draft) => {
      draft.pageNumber = event.page === undefined ? 1 : (event.page as number) + 1
      draft.pageSize = event.rows
      draft.first = event.first
      draft.sortField = event.sortField
      event.sortField && (draft.order = event.sortOrder === 1 ? 'asc' : 'desc')
      event.sortField &&
        (draft.orderBy = [`${event.sortField} ${event.sortOrder === 1 ? 'asc' : 'desc'}`])
    })
  }

  const renderHeader = () => (
    <div className='flex justify-content-end'>
      <span className='p-input-icon-left'>
        <i className='pi pi-search' />
        <InputText
          placeholder='Keyword Search'
          value={globalFilterValue}
          onChange={(e) => setGlobalFilterValue(e.target.value)}
        />
      </span>
    </div>
  )

  const header = renderHeader()

  const filteredData = response?.data?.filter((item) =>
    Object.values(item).some((value: any) => {
      return (
        typeof value === 'string' && value.toLowerCase().includes(globalFilterValue.toLowerCase())
      )
    })
  )
  const leftContent = (
    <span className='mx-3' style={{color: 'var(--text-color)', userSelect: 'none'}}>
      {`Showing ${(response?.pagination?.totalCount as number) > 0 ? (paginationFilters.first as number) + 1 : 0} to 
            ${Math.min(
              (response?.pagination?.currentPage as number) *
                (response?.pagination?.pageSize as number),
              response?.pagination?.totalCount as number
            )} 
            out of ${response?.pagination?.totalCount} Records`}
    </span>
  )
  return (
    <>
      <ProductStockAdvanceSearch refetch={refetch} onFilterQueryChange={handleFilterQueryChange} />
      <KTCard>
        <>
          <div className='card-header justify-content-between align-items-center'>
            <div className='card-title'>
              <h3>Products</h3>
            </div>
          </div>
          <KTCardBody className='py-4'>
            <DataTable
              value={filteredData}
              stripedRows
              header={header}
              showGridlines
              filterDisplay='menu'
              tableClassName='table table-bordered table-hover mb-0'
              tableStyle={{minWidth: '50rem'}}
              sortOrder={paginationFilters.order === 'asc' ? 1 : -1}
              sortField={paginationFilters?.sortField}
              loading={isLoading}
              sortMode='single'
              currentPageReportTemplate='Showing {first} to {last} of {totalRecords} records'
              paginatorClassName='dataTables_paginate paging_bootstrap_full_number mt-0'
              paginator
              paginatorLeft={leftContent}
              rows={paginationFilters.pageSize}
              totalRecords={response?.pagination?.totalCount}
              emptyMessage='No Record found.'
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              onSort={onSort}
              onPage={onSort}
              first={paginationFilters.first}
              lazy
            >
              <Column
                field={'productName'}
                header={'Product Name'}
                sortable={true}
                sortField='Product.name'
              />
              <Column
                field={'availableQuantity'}
                header={'Available Quantity'}
                sortable={true}
                sortField='AvailableQuantity'
              />
              <Column
                field={'colorName'}
                header={'Color Name'}
                sortable={true}
                sortField='Product.ColorId'
              />

              <Column
                field={'polishingTypeName'}
                header={'Polishing Type'}
                sortable={true}
                sortField='Product.PolishingTypeId'
              />

              <Column
                field={'lastUpdatedOn'}
                header={'last Updated On'}
                body={(rowData) => formatDate(rowData?.lastUpdatedOn, 'dd MMM yyyy')}
                sortable={true}
                sortField='LastUpdatedOn'
              />
            </DataTable>

            {isLoading && <Loading />}
          </KTCardBody>

          <div className='card-footer text-end'>
            <button className='btn btn-primary' onClick={() => navigate('/reports/stocks/list')}>
              <i className='ki-duotone ki-left fs-2' /> Back
            </button>
          </div>
        </>
      </KTCard>
    </>
  )
}

export default ProductStockList
