import {FC, useState} from 'react'
import {
  defaultPageSize,
  KTCard,
  KTCardBody,
  KTIcon,
  pageSizeOption,
  PaginationFilter,
} from '../../../../../_metronic/helpers'
import {useImmer} from 'use-immer'
import {useQuery} from 'react-query'
import {DataTable, DataTableStateEvent, SortOrder} from 'primereact/datatable'
import {InputText} from 'primereact/inputtext'
import {Loading} from '../../../../core/shared/components/Loading'
import {Column} from 'primereact/column'
import {formatCurrency, formatDate} from '../../../../core/utils/helpers'
import clsx from 'clsx'
import ActionDropdown from '../../../../core/shared/components/ActionDropdown'
import {DropdownItem} from '../../../../core/models/DropdownItem'

import {
  MustHavePermission,
  SWAction,
  SWResource,
  SWSection,
} from '../../../../core/shared/core/permissionUtils'
import {useNavigate} from 'react-router-dom'
import {deletePayment, getPaymentList} from '../core/_request'
import {PaymentListDto} from '../core/_model'
import {confirmDialog, ConfirmDialog} from 'primereact/confirmdialog'
import {toast} from 'react-toastify'
import {Result} from '../../../../core/models/Result'

const PaymentList: FC = () => {
  const navigate = useNavigate()
  const [isExpand, setExpand] = useState<boolean>(false)
  const [globalFilterValue, setGlobalFilterValue] = useState<string>('')
  const [paginationModel, setPaginationModel] = useImmer<PaginationFilter>({
    first: 0,
    pageSize: defaultPageSize,
    sortField: 'paymentDate',
    sortOrder: -1,
    orderBy: ['paymentDate desc'],
  })

  const [filterStatus, updateFilterStatus] = useState<boolean | undefined>()
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined)
  const [filterName, updateFilterName] = useState<string>('')
  const [filterOrderNumber, updateFilterorderNumber] = useState<string>('')
  const [filterOrderDateFrom, setFilterOrderDateFrom] = useState<string | undefined | Date>(
    undefined
  )
  const [filterOrderDateTo, setFilterOrderDateTo] = useState<string | undefined | Date>(undefined)

  const {
    data: paymentResponse,
    isLoading,
    refetch,
  } = useQuery(['payment-list', paginationModel], () => {
    return getPaymentList({...paginationModel})
  })

  const leftContent = (
    <span className='mx-3' style={{color: 'var(--text-color)', userSelect: 'none'}}>
      {`Showing ${(paymentResponse?.pagination?.totalCount as number) > 0 ? (paginationModel.first as number) + 1 : 0} to 
            ${Math.min(
              (paymentResponse?.pagination?.currentPage as number) *
                (paymentResponse?.pagination?.pageSize as number),
              paymentResponse?.pagination?.totalCount as number
            )} 
            out of ${paymentResponse?.pagination?.totalCount} Records`}
    </span>
  )

  const onPageOrSortChange = (event: DataTableStateEvent) => {
    setPaginationModel((draft) => {
      draft.pageNumber = event.page === undefined ? 1 : (event.page as number) + 1
      draft.pageSize = event.rows
      draft.first = event.first
      draft.sortField = event.sortField
      draft.sortOrder = event.sortOrder as SortOrder
      draft.orderBy = [`${event.sortField} ${event.sortOrder === 1 ? 'asc' : 'desc'}`]
    })
  }

  const renderHeader = () => (
    <div className='flex justify-content-end'>
      <span className='p-input-icon-left'>
        <i className='pi pi-search' />
        <InputText
          placeholder='Keyword Search'
          value={globalFilterValue}
          onChange={(e) => setGlobalFilterValue(e.target.value)}
        />
      </span>
    </div>
  )

  const header = renderHeader()

  const filteredData = paymentResponse?.data?.filter((item: PaymentListDto) =>
    Object.values(item).some((value: any) => {
      return (
        typeof value === 'string' && value.toLowerCase().includes(globalFilterValue.toLowerCase())
      )
    })
  )

  const accept = async (pid: number) => {
    let result: Result
    try {
      result = await deletePayment(pid)
      if (result.succeeded) {
        toast.success('Payment Deleted Successfully.')
        refetch()
      } else {
        toast.success('Error Occurred during deletion of payment.')
      }
    } catch (ex) {
      console.error(ex)
    }
  }

  const deleteConfirmation = (pid: number) => {
    confirmDialog({
      message: 'Are you sure you want to proceed?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      defaultFocus: 'accept',
      accept: () => accept(pid),
    })
  }

  return (
    <>
      <ConfirmDialog />
      <KTCard>
        <div className='card-header'>
          <div className='card-title justify-content-between w-100'>
            <h3>Payment List</h3>
            <div className='d-flex align-items-center gap-2 gap-lg-3'>
              {MustHavePermission(SWSection.Store, SWResource.SaleOrders, SWAction.Create) ? (
                <button
                  type='button'
                  className='btn btn-primary'
                  onClick={() => navigate('/sales/payment/add')}
                >
                  <KTIcon iconName='plus' className='fs-2' />
                  Add
                </button>
              ) : null}
            </div>
          </div>
        </div>
        <KTCardBody className='py-4'>
          <DataTable
            value={filteredData}
            stripedRows
            lazy
            loading={isLoading}
            showGridlines
            dataKey='id'
            header={header}
            responsiveLayout='stack'
            rows={paginationModel.pageSize}
            sortMode='single'
            emptyMessage='No Sales Order found.'
            tableClassName='table table-bordered table-hover mb-0'
            paginator
            currentPageReportTemplate='Showing {first} to {last} of {totalRecords} records'
            paginatorClassName='dataTables_paginate paging_bootstrap_full_number mt-0'
            paginatorLeft={leftContent}
            onSort={onPageOrSortChange}
            onPage={onPageOrSortChange}
            first={paginationModel.first}
            totalRecords={paymentResponse?.pagination?.totalCount}
            rowsPerPageOptions={pageSizeOption}
            sortField={paginationModel.sortField}
            sortOrder={paginationModel.sortOrder}
          >
            <Column
              field='paymentNumber'
              header='Payment Number'
              sortable
              sortField='paymentNumber'
            />
            <Column
              field='paymentDate'
              header='Payment Date'
              sortable
              sortField='paymentDate'
              body={(rowData: PaymentListDto) => formatDate(rowData.paymentDate, 'dd MMM yyyy')}
            />
            <Column
              field='customerName'
              header='Customer'
              sortable
              sortField='customer.printName'
            />
            <Column
              field='amountReceived'
              header='Amount Received'
              sortable
              sortField='amountReceived'
              body={(rowData: PaymentListDto) => formatCurrency(rowData.amountReceived)}
            />
            <Column
              field='advanceAmountLeft'
              header='Unused Amount'
              sortable
              sortField='advanceAmountLeft'
              body={(rowData: PaymentListDto) => formatCurrency(rowData.advanceAmountLeft ?? 0)}
            />
            <Column
              field='paymentModeName'
              header='Payment Mode'
              sortable
              sortField='paymentModeId'
            />
            <Column
              field=''
              header='Actions'
              body={(rowData: PaymentListDto) => (
                <PaymentAction
                  id={rowData.id}
                  rowData={rowData}
                  showDeleteModal={(pid: number) => {
                    deleteConfirmation(pid)
                  }}
                />
              )}
            />
          </DataTable>
          {isLoading && <Loading />}
        </KTCardBody>
      </KTCard>
    </>
  )
}

interface PaymentActionProps {
  id: number
  rowData: PaymentListDto
  showDeleteModal: (paymentId: number) => void
}

const PaymentAction: FC<PaymentActionProps> = ({id, rowData, showDeleteModal}) => {
  const actionLinks: DropdownItem[] = []

  // const actionLinks: DropdownItem[] = [
  //     { id: 1, label: 'Edit', href: `` },
  //     { id: 2, label: 'View Details', href: `` },
  // ];

  // if (rowData.saleOrderStatusId !== 3) {
  //   actionLinks.push({id: 1, label: `Edit Order`, href: `/sales/sale-order/edit/${id}`})
  // }
  MustHavePermission(SWSection.Store, SWResource.PaymentReceived, SWAction.View) &&
    actionLinks.push({id: 1, label: `View Details`, href: `/sales/payment/detail/${id}`})

  MustHavePermission(SWSection.Store, SWResource.PaymentReceived, SWAction.Delete) &&
    actionLinks.push({
      id: 7,
      label: `Delete Payment`,
      href: `#`,
      onClick: () => deletePayment(rowData),
    })

  // if (rowData.paymentStatusId != 3 && rowData.saleOrderStatusId !== 3) {
  //   actionLinks.push({id: 3, label: `Update Payment`, href: `/sales/sale-order/payment/${id}`})
  // }

  // if (
  //   rowData.saleOrderStatusId !== 5 &&
  //   rowData.saleOrderStatusId !== 7 &&
  //   rowData.saleOrderStatusId !== 3
  // ) {
  //   actionLinks.push({id: 4, label: `Add Package`, href: `/sales/package/add/${id}`})
  // }

  // if (
  //   rowData.saleOrderStatusId !== 9 &&
  //   rowData.saleOrderStatusId !== 7 &&
  //   rowData.saleOrderStatusId !== 3
  // ) {
  //   actionLinks.push({id: 5, label: `Add Shipment`, href: `/sales/shipment/add/${id}`})
  // }

  // if (rowData.saleOrderStatusId !== 3) {
  //   actionLinks.push({
  //     id: 6,
  //     label: `Convert to Tax Sale Order`,
  //     href: `#`,
  //     onClick: () => convertToTaxSaleOrder(rowData),
  //   })
  // }

  // if (
  //   rowData.saleOrderStatusId !== 3 &&
  //   rowData.saleOrderStatusId !== 9 &&
  //   rowData.saleOrderStatusId !== 7
  // ) {
  //   actionLinks.push({
  //     id: 7,
  //     label: `Cancel Order`,
  //     href: `#`,
  //     onClick: () => cancelSaleOrder(rowData),
  //   })
  // }

  const deletePayment = async (rowData: PaymentListDto) => {
    showDeleteModal(rowData.id)
  }

  // const convertToTaxSaleOrder = async (rowData: SaleOrderDto) => {
  //   showConvertToTaxSaleOrderModal(rowData.id)
  // }

  return <ActionDropdown buttonLabel='Actions' items={actionLinks} />
}

export default PaymentList
