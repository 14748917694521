import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate, Outlet} from 'react-router-dom'
import {MasterLayout} from '../../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../../_metronic/helpers'
// import {SalesOrderSetting} from './pages/SalesOrderSetting'

import {SeriesEditPage} from './pages/seriesSettings/SeriesEditPage'
import AccountPage from './account/AccountPage'
import {CustomerVendorPage} from './pages/customervendor/CustomerVendorPage'
import {EmailSettingPage} from './pages/emailSetting/EmailSettingPage'
import {SaleOrderPage} from './salesSetting/components/saleorder/SaleOrderPage'
import EmailTemplatePage from './emailTemplate/EmailTemplatePage'
import {PurchaseOrderPage} from './pages/purchaseOrder/PurchaseOrderPage'
import {InvoiceSettingPage} from './salesSetting/components/invoicesetting/InvoiceSettingPage'
import {OrganizationProfilePage} from './generalsetting/components/organizationProfile/OrganizationProfilePage'
import {GSTPage} from './generalsetting/components/gstSetting/GSTPage'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import GeneralHeader from './generalsetting/GeneralHeader'
import General from './generalsetting/components/general/General'
import SaleSetting from './salesSetting/components/creditNote/SaleSetting'
import SalesGeneralHeader from './salesSetting/SalesGeneralHeader'
import PaymentModePage from './paymode/PaymentModePage'
import RolePage from './roles/RolePage'

import {
  MustHavePermission,
  SWSection,
  SWResource,
  SWAction,
} from '../../core/shared/core/permissionUtils'
import UnAuthorized from '../errors/components/UnAuthorized'
import BankAccountPage from './bankAccount/BankAccountPage'
import AddressesPage from './generalsetting/components/addresses/AddressesPage'

const SettingRoutes = () => {
  const StaffPage = lazy(() => import('./staff/StaffPage'))
  const Breadcrumbs: Array<PageLink> = [
    {
      title: 'Home',
      path: '/dashboard',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
    {
      title: 'Settings',
      path: '',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route
          element={
            <>
              <GeneralHeader />
              <Outlet />
            </>
          }
        >
          <Route
            path='general/*'
            element={
              <>
                <PageTitle breadcrumbs={Breadcrumbs}>Overview</PageTitle>
                <General />
              </>
            }
          />
          <Route
            path='gstsetting/*'
            index
            element={
              <>
                <PageTitle breadcrumbs={Breadcrumbs}>GST</PageTitle>
                <GSTPage />
              </>
            }
          />
          <Route
            path='address/*'
            index
            element={
              <>
                <PageTitle breadcrumbs={Breadcrumbs}>Address</PageTitle>
                <AddressesPage />
              </>
            }
          />
          <Route
            path='organizationprofile/*'
            element={
              <>
                <PageTitle breadcrumbs={Breadcrumbs}>Organization Profile</PageTitle>
                {MustHavePermission(
                  SWSection.Store,
                  SWResource.OrganizationProfiles,
                  SWAction.View
                ) ? (
                  <OrganizationProfilePage />
                ) : (
                  <UnAuthorized />
                )}
              </>
            }
          />
        </Route>

        <Route
          element={
            <>
              <SalesGeneralHeader />
              <Outlet />
            </>
          }
        >
          <Route
            path='salesetting/*'
            index
            element={
              <>
                <PageTitle breadcrumbs={Breadcrumbs}>Sale Setting</PageTitle>
                {MustHavePermission(
                  SWSection.Store,
                  SWResource.CreditNoteSettings,
                  SWAction.View
                ) ? (
                  <SaleSetting />
                ) : (
                  <UnAuthorized />
                )}
              </>
            }
          />

          <Route
            path='invoicesetting/*'
            element={
              <>
                <PageTitle breadcrumbs={Breadcrumbs}>Invoice Setting</PageTitle>
                {MustHavePermission(SWSection.Store, SWResource.InvoiceSettings, SWAction.View) ? (
                  <InvoiceSettingPage />
                ) : (
                  <UnAuthorized />
                )}
              </>
            }
          />

          <Route
            path='saleordersetting/*'
            element={
              <>
                <PageTitle breadcrumbs={Breadcrumbs}>SaleOrder Setting</PageTitle>
                {MustHavePermission(
                  SWSection.Store,
                  SWResource.SalesOrderSettings,
                  SWAction.View
                ) ? (
                  <SaleOrderPage />
                ) : (
                  <UnAuthorized />
                )}
              </>
            }
          />
        </Route>
        <Route
          path='staff/*'
          element={
            <SuspensedView>
              {MustHavePermission(SWSection.Store, SWResource.Staffs, SWAction.Search) ? (
                <StaffPage />
              ) : (
                <UnAuthorized />
              )}
            </SuspensedView>
          }
        />
        <Route
          path='role/*'
          element={
            <SuspensedView>
              {MustHavePermission(SWSection.Store, SWResource.Roles, SWAction.Search) ? (
                <RolePage />
              ) : (
                <UnAuthorized />
              )}
            </SuspensedView>
          }
        />
        <Route
          path='paymentmode/*'
          element={
            <SuspensedView>
              {MustHavePermission(SWSection.Store, SWResource.PaymentModes, SWAction.Search) ? (
                <PaymentModePage />
              ) : (
                <UnAuthorized />
              )}
            </SuspensedView>
          }
        />
        <Route
          path='account/*'
          element={
            <SuspensedView>
              {MustHavePermission(SWSection.Store, SWResource.Accounts, SWAction.Search) ? (
                <AccountPage />
              ) : (
                <UnAuthorized />
              )}
            </SuspensedView>
          }
        />

        <Route
          path='bank-account/*'
          element={
            <SuspensedView>
              {MustHavePermission(SWSection.Store, SWResource.Accounts, SWAction.Search) ? (
                <BankAccountPage />
              ) : (
                <UnAuthorized />
              )}
            </SuspensedView>
          }
        />

        <Route
          path='purchasesetting/*'
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>Purchase Order Setting</PageTitle>
              {MustHavePermission(
                SWSection.Store,
                SWResource.PurchaseOrderSettings,
                SWAction.Search
              ) ? (
                <PurchaseOrderPage />
              ) : (
                <UnAuthorized />
              )}
            </>
          }
        />
        <Route
          path='customervendor/*'
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>Customer Vendor Setting</PageTitle>
              {MustHavePermission(
                SWSection.Store,
                SWResource.CustomerVendorSettings,
                SWAction.Search
              ) ? (
                <CustomerVendorPage />
              ) : (
                <UnAuthorized />
              )}
            </>
          }
        />
        <Route
          path='emailsetting/*'
          element={
            <>
              <PageTitle breadcrumbs={Breadcrumbs}>Email Setting</PageTitle>
              {MustHavePermission(SWSection.Store, SWResource.EmailSettings, SWAction.Search) ? (
                <EmailSettingPage />
              ) : (
                <UnAuthorized />
              )}
            </>
          }
        />
        <Route
          path='emailtemplate/*'
          element={
            <SuspensedView>
              {MustHavePermission(SWSection.Store, SWResource.EmailTemplates, SWAction.Search) ? (
                <EmailTemplatePage />
              ) : (
                <UnAuthorized />
              )}
            </SuspensedView>
          }
        />
        <Route
          path='autonumbergeneration-setting'
          element={
            <SuspensedView>
              {MustHavePermission(
                SWSection.Store,
                SWResource.AutoNumberGenerationGroups,
                SWAction.Search
              ) ? (
                <SeriesEditPage />
              ) : (
                <UnAuthorized />
              )}
            </SuspensedView>
          }
        />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })

  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {SettingRoutes}
