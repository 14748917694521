import axios, {AxiosResponse} from 'axios'
import {FileResult, Result} from '../../../../core/models/Result'
import {
  ExpenseCategory,
  ExpenseCategoryQueryResponse,
  ExpenseQueryResponse,
  ExpenseReport,
  PurchaseByProductGroupStock,
  PurchaseByProductGroupStockQueryResponse,
  PurchaseByProductStock,
  PurchaseByProductStockQueryResponse,
  PurchaseByVendorStock,
  PurchaseByVendorStockQueryResponse,
  SalesByAgentStockQueryResponse,
  SalesByCustomerStockQueryResponse,
  SalesByProductGroupStock,
  SalesByProductGroupStockQueryResponse,
  SalesByProductStock,
  SalesByProductStockQueryResponse,
  StockFilter,
} from './_model'
import {PaginationFilter} from '../../../../../_metronic/helpers'

const API_URL = process.env.REACT_APP_STORE_API_URL
const SALES_BY_CUSTOMER_STOCK_LIST_URL = `${API_URL}/report/sales-by-customer`
const SALES_BY_AGENT_STOCK_LIST_URL = `${API_URL}/report/sales-by-agent`
const SALES_BY_PRODUCT_LIST_URL = `${API_URL}/report/sales-by-product`
const SALES_BY_PRODUCTGROUP_LIST_URL = `${API_URL}/report/sales-by-product-group`
const PURCHASE_BY_VENDOR_LIST_URL = `${API_URL}/report/purchases-by-vendor`
const PURCHASE_BY_PRODUCTGROUP_LIST_URL = `${API_URL}/report/purchases-by-product-group`
const PURCHASE_BY_PRODUCT_LIST_URL = `${API_URL}/report/purchases-by-product`
const EXPENSE_LIST_URL = `${API_URL}/report/expense-details`
const EXPENSE_CATEGORY_LIST_URL = `${API_URL}/report/expense-by-category`

const getSalesByCustomerStock = (
  filters: StockFilter
): Promise<SalesByCustomerStockQueryResponse> => {
  return axios
    .post(`${SALES_BY_CUSTOMER_STOCK_LIST_URL}`, filters)
    .then((d: AxiosResponse<SalesByCustomerStockQueryResponse>) => {
      return d.data
    })
    .catch((err) => {
      return err
    })
}

const getSalesByAgentStock = (filters: StockFilter): Promise<SalesByAgentStockQueryResponse> => {
  return axios
    .post(`${SALES_BY_AGENT_STOCK_LIST_URL}`, filters)
    .then((d: AxiosResponse<SalesByAgentStockQueryResponse>) => {
      return d.data
    })
    .catch((err) => {
      return err
    })
}

const getSalesProductStock = (filters: StockFilter): Promise<SalesByProductStock[]> => {
  return axios
    .post(`${SALES_BY_PRODUCT_LIST_URL}`, filters)
    .then((d: AxiosResponse<SalesByProductStock[]>) => {
      return d.data
    })
    .catch((err) => {
      return err
    })
}

const getSalesProductGroupStock = (filters: StockFilter): Promise<SalesByProductGroupStock[]> => {
  return axios
    .post(`${SALES_BY_PRODUCTGROUP_LIST_URL}`, filters)
    .then((d: AxiosResponse<SalesByProductGroupStock[]>) => {
      return d.data
    })
    .catch((err) => {
      return err
    })
}

const getPurchaseVendorStock = (filters: StockFilter): Promise<PurchaseByVendorStock[]> => {
  return axios
    .post(`${PURCHASE_BY_VENDOR_LIST_URL}`, filters)
    .then((d: AxiosResponse<PurchaseByVendorStock[]>) => {
      return d.data
    })
    .catch((err) => {
      return err
    })
}

const getPurchaseProductStock = (filters: StockFilter): Promise<PurchaseByProductStock[]> => {
  return axios
    .post(`${PURCHASE_BY_PRODUCT_LIST_URL}`, filters)
    .then((d: AxiosResponse<PurchaseByProductStock[]>) => {
      return d.data
    })
    .catch((err) => {
      return err
    })
}
const getPurchaseProductGroupStock = (
  filters: StockFilter
): Promise<PurchaseByProductGroupStock[]> => {
  return axios
    .post(`${PURCHASE_BY_PRODUCTGROUP_LIST_URL}`, filters)
    .then((d: AxiosResponse<PurchaseByProductGroupStock[]>) => {
      return d.data
    })
    .catch((err) => {
      return err
    })
}

const getExpenseReport = (filters: StockFilter): Promise<ExpenseReport[]> => {
  return axios
    .post(`${EXPENSE_LIST_URL}`, filters)
    .then((d: AxiosResponse<ExpenseReport[]>) => {
      return d.data
    })
    .catch((err) => {
      return err
    })
}

const getExpenseCategoryReport = (filters: StockFilter): Promise<ExpenseCategory[]> => {
  return axios
    .post(`${EXPENSE_CATEGORY_LIST_URL}`, filters)
    .then((d: AxiosResponse<ExpenseCategory[]>) => {
      return d.data
    })
    .catch((err) => {
      return err
    })
}

const GeneratePdf = async (filters: any, url: string): Promise<any | FileResult> => {
  return await axios
    .post(`${API_URL}/${url}`, filters, {
      responseType: 'blob',
    })
    .then(async (response: any) => {
      if (response.type == 'application/json') {
        const jsonData = await response.text()
        const errorData = JSON.parse(jsonData)
        return errorData
      } else {
        let filename = response.headers['content-disposition']
          .split(';')
          .find((n: any) => n.includes('filename='))
          .replace('filename=', '')
          .trim()

        var result: FileResult = {
          data: response.data,
          name: filename,
        }
        return result
      }
    })
    .catch((err: Result) => {
      return err
    })
}

export {
  getSalesByCustomerStock,
  getSalesByAgentStock,
  getSalesProductStock,
  getSalesProductGroupStock,
  getPurchaseVendorStock,
  getPurchaseProductGroupStock,
  getPurchaseProductStock,
  getExpenseReport,
  getExpenseCategoryReport,
  GeneratePdf,
}
