import {useNavigate} from 'react-router-dom'
import {KTCard, KTCardBody} from '../../../../_metronic/helpers'

const ReportDetailPage = () => {
  const navigate = useNavigate()
  return (
    <>
      <KTCard>
        <div className='card-header'>
          <div className='card-title'>
            <h3>All Reports</h3>
          </div>
        </div>
        <KTCardBody>
          {/* <div className="fs-4 fw-bold mb-4">All Reports</div> */}
          <div className='table-responsive'>
            <table className='table border table-striped table-row-bordered gy-5 gs-7'>
              <thead>
                <tr className='fw-semibold fs-6 text-gray-800'>
                  <th>Report Name</th>
                  <th>Type</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody className=''>
                <tr>
                  <td>Product Stock</td>
                  <td>Stock</td>
                  <td
                    className='table-cursor btn btn-light btn-active-light-primary btn-sm mt-2 mb-2'
                    onClick={() => navigate('/reports/stocks/productstock/list')}
                  >
                    <a>Open Report</a>
                  </td>
                </tr>

                <tr>
                  <td>Sales By Customer</td>
                  <td>Sales</td>
                  <td
                    className='table-cursor btn btn-light btn-active-light-primary btn-sm mt-2 mb-2'
                    onClick={() => navigate('/reports/stocks/sales-customer/list')}
                  >
                    <a>Open Report</a>{' '}
                  </td>
                </tr>
                <tr>
                  <td>Sales By Agent</td>
                  <td>Sales</td>
                  <td
                    className='table-cursor btn btn-light btn-active-light-primary btn-sm mt-2 mb-2'
                    onClick={() => navigate('/reports/stocks/sales-agent/list')}
                  >
                    <a>Open Report</a>
                  </td>
                </tr>
                <tr>
                  <td>Sales By Product</td>
                  <td>Sales</td>
                  <td
                    className='table-cursor btn btn-light btn-active-light-primary btn-sm mt-2 mb-2'
                    onClick={() => navigate('/reports/stocks/sales-product/list')}
                  >
                    <a>Open Report</a>
                  </td>
                </tr>
                <tr>
                  <td>Sales By Product Group</td>
                  <td>Sales</td>
                  <td
                    className='table-cursor btn btn-light btn-active-light-primary btn-sm mt-2 mb-2'
                    onClick={() => navigate('/reports/stocks/sales-product-group/list')}
                  >
                    <a>Open Report</a>
                  </td>
                </tr>

                <tr>
                  <td>Purchase By Vendor</td>
                  <td>Purchase </td>
                  <td
                    className='table-cursor btn btn-light btn-active-light-primary btn-sm mt-2 mb-2'
                    onClick={() => navigate('/reports/stocks/purchase-vendor/list')}
                  >
                    <a>Open Report</a>
                  </td>
                </tr>

                <tr>
                  <td>Purchase By Product</td>
                  <td>Purchase</td>
                  <td
                    className='table-cursor btn btn-light btn-active-light-primary btn-sm mt-2 mb-2'
                    onClick={() => navigate('/reports/stocks/purchase-product/list')}
                  >
                    <a>Open Report</a>
                  </td>
                </tr>
                <tr>
                  <td>Purchase By Product Group</td>
                  <td>Purchase</td>
                  <td
                    className='table-cursor btn btn-light btn-active-light-primary btn-sm mt-2 mb-2'
                    onClick={() => navigate('/reports/stocks/purchase-product-group/list')}
                  >
                    {' '}
                    <a>Open Report</a>
                  </td>
                </tr>

                <tr>
                  <td>Expense</td>
                  <td>Expense</td>
                  <td
                    className='table-cursor btn btn-light btn-active-light-primary btn-sm mt-2 mb-2'
                    onClick={() => navigate('/reports/stocks/expense/list')}
                  >
                    <a>Open Report</a>
                  </td>
                </tr>

                <tr>
                  <td>Expense Category</td>
                  <td>Expense</td>
                  <td
                    className='table-cursor btn btn-light btn-active-light-primary btn-sm mt-2 mb-2'
                    onClick={() => navigate('/reports/stocks/expense-category/list')}
                  >
                    <a>Open Report</a>
                  </td>
                </tr>

                <tr>
                  <td>Product Low Stock</td>
                  <td>Low Stock</td>
                  <td
                    className='table-cursor btn btn-light btn-active-light-primary btn-sm mt-2 mb-2'
                    onClick={() => navigate('/reports/lowstock/product/list')}
                  >
                    <a>Open Report</a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </KTCardBody>
      </KTCard>
    </>
  )
}
export default ReportDetailPage
