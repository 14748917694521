import React from 'react'
import PermissionsPage from './PermissionsPage'
import {useParams} from 'react-router-dom'
import {useQuery} from 'react-query'
import {QUERIES} from '../../../../../_metronic/helpers'
import {getRoleById} from '../core/_requests'
import {getPermissionsList} from '../../../../core/shared/core/_request'

const PermissionWrapper = () => {
  const {id} = useParams()
  const {
    isLoading,
    data: result,
    error,
  } = useQuery(
    `${QUERIES.ROLE_LIST}-${id}`,
    async () => {
      return await getRoleById(id)
    },
    {
      cacheTime: 0,
      enabled: true,
      onError: (err) => {
        console.error(err)
      },
    }
  )
  const {
    isLoading: allPermissionsLoading,
    data: allPermissions,
    error: allPernissionsError,
  } = useQuery(
    'AllPermissionsListQuery',
    async () => {
      return await getPermissionsList()
    },
    {
      cacheTime: 0,
      enabled: true,
      onError: (err) => {
        console.error(err)
      },
    }
  )
  if (!isLoading && !error && result && !allPermissionsLoading) {
    return (
      <PermissionsPage isRoleLoading={isLoading} data={result} allPermissions={allPermissions} />
    )
  }

  return null
}

export default PermissionWrapper
