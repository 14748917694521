import {lazy, FC, Suspense} from 'react'
import {Route, Routes} from 'react-router-dom'
import {MasterLayout} from '../../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../../_metronic/helpers'
import PaymentPage from './payment/payment/PaymentPage'
import ExpenseCategoryPage from './expenseCategory/ExpenseCategoryPage'
import ExpensePage from './expense/ExpensePage'
import {
  MustHavePermission,
  SWSection,
  SWResource,
  SWAction,
} from '../../core/shared/core/permissionUtils'
import UnAuthorized from '../errors/components/UnAuthorized'
import PaymentMadePage from './paymentMade/PaymentMadePage'

const PurchaseRoutes = () => {
  const VendorPage = lazy(() => import('./vendor/VendorPage'))
  const PurchaseOrderPage = lazy(() => import('./purchaseOrder/PurchaseOrderPage'))
  const PurchaseReceivePage = lazy(() => import('./purchaseReceive/PurchaseReceivePage'))
  const BillsPage = lazy(() => import('./bills/BillsPage'))

  const permissions = MustHavePermission(SWSection.Store, SWResource.Bills, SWAction.Search)
  console.log(permissions)
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route
          path='vendor/*'
          element={
            <SuspensedView>
              {MustHavePermission(SWSection.Store, SWResource.Vendors, SWAction.Search) ? (
                <VendorPage />
              ) : (
                <UnAuthorized />
              )}
            </SuspensedView>
          }
        />
        <Route
          path='purchase-order/*'
          element={
            <SuspensedView>
              {MustHavePermission(SWSection.Store, SWResource.PurchaseOrders, SWAction.Search) ? (
                <PurchaseOrderPage />
              ) : (
                <UnAuthorized />
              )}
            </SuspensedView>
          }
        />
        <Route
          path='purchase-receive/*'
          element={
            <SuspensedView>
              {MustHavePermission(SWSection.Store, SWResource.PurchaseReceives, SWAction.Search) ? (
                <PurchaseReceivePage />
              ) : (
                <UnAuthorized />
              )}
            </SuspensedView>
          }
        />
        <Route
          path='payment/*'
          element={
            <SuspensedView>
              <PaymentPage />
            </SuspensedView>
          }
        />
        <Route
          path='expensecategory/*'
          element={
            <SuspensedView>
              {MustHavePermission(
                SWSection.Store,
                SWResource.ExpenseCategories,
                SWAction.Search
              ) ? (
                <ExpenseCategoryPage />
              ) : (
                <UnAuthorized />
              )}
            </SuspensedView>
          }
        />

        <Route
          path='expense/*'
          element={
            <SuspensedView>
              {MustHavePermission(SWSection.Store, SWResource.Expenses, SWAction.Search) ? (
                <ExpensePage />
              ) : (
                <UnAuthorized />
              )}
            </SuspensedView>
          }
        />

        <Route
          path='bills/*'
          element={
            <SuspensedView>
              {MustHavePermission(SWSection.Store, SWResource.Bills, SWAction.Search) ? (
                <BillsPage />
              ) : (
                <UnAuthorized />
              )}
            </SuspensedView>
          }
        />
        <Route
          path='payment-made/*'
          element={
            <SuspensedView>
              {MustHavePermission(SWSection.Store, SWResource.PaymentMades, SWAction.Search) ? (
                <PaymentMadePage />
              ) : (
                <UnAuthorized />
              )}
            </SuspensedView>
          }
        />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })

  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PurchaseRoutes}
