import {FC} from 'react'
import ActionDropdown from '../../../../core/shared/components/ActionDropdown'
import {InvoiceDto} from '../core/_models'
import {DropdownItem} from '../../../../core/models/DropdownItem'
import {
  MustHavePermission,
  SWAction,
  SWResource,
  SWSection,
} from '../../../../core/shared/core/permissionUtils'

interface InvoiceActionProps {
  id: number
  rowData: InvoiceDto
  showCancelModal: (orderId: number) => void
}

const InvoiceAction: FC<InvoiceActionProps> = ({id, rowData, showCancelModal}) => {
  const actionLinks: DropdownItem[] = []

  // if (rowData.invoiceStatusId !== 3) {
  //   actionLinks.push({id: 1, label: `Edit Invoice`, href: `/sales/invoice/edit/${id}`})
  // }
  MustHavePermission(SWSection.Store, SWResource.Invoices, SWAction.View) &&
    actionLinks.push({id: 2, label: `View Details`, href: `/sales/invoice/detail/${id}`})

  if (rowData.invoiceStatusId !== 3) {
    MustHavePermission(SWSection.Store, SWResource.Invoices, SWAction.Update) &&
      actionLinks.push({
        id: 3,
        label: `Cancel Invoice`,
        href: `#`,
        onClick: () => cancelInvoice(rowData),
      })
  }

  const cancelInvoice = async (rowData: InvoiceDto) => {
    showCancelModal(rowData.id)
  }

  return <ActionDropdown buttonLabel='Actions' items={actionLinks} />
}

export default InvoiceAction
